import {TimesheetSchedule, TimesheetScheduleAdd} from '@/system/timesheetSchedule/store/TimesheetSchedule';
import {api} from '@/shared/api';

export async function listTimesheetSchedules(): Promise<TimesheetSchedule[]> {
    const {data} = await api<TimesheetSchedule[]>({
        method: 'get',
        url: 'Timesheets/timesheetSchedule',
    });

    return data;
}

export async function createTimesheetSchedule(timesheetSchedule: TimesheetScheduleAdd): Promise<TimesheetSchedule> {
    const {data} = await api<TimesheetSchedule>({
        method: 'post',
        url: 'Timesheets/timesheetSchedule',
        data: timesheetSchedule,
    });

    return data;
}

export async function deleteTimesheetSchedule(timesheetSchedule: TimesheetSchedule): Promise<void> {
    await api<TimesheetSchedule>({
        method: 'delete',
        url: `Timesheets/timesheetSchedule/${timesheetSchedule.id}`,
    });
}

export async function updateTimesheetSchedule(timesheetSchedule: TimesheetSchedule): Promise<TimesheetSchedule> {
    const {data} = await api<TimesheetSchedule>({
        method: 'put',
        url: 'Timesheets/timesheetSchedule',
        data: timesheetSchedule,
    });

    return data;
}
