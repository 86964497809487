






















import { Component, Prop, Vue } from 'vue-property-decorator';
import AppDialog from '@/shared/AppDialog.vue';

@Component({
    components: {
        AppDialog,
    },
})
export default class ProjectsActivitiesModal extends Vue {
    @Prop(String)
    title!: string;

    @Prop(Boolean)
    show!: boolean;
}

