






























import {Component, Mixins} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {FileExtension} from '@/system/store/FileExtension';
import { addFileExtensions, getFileExtensions, removeExtension } from './store/endpoints';

@Component
export default class ExtensionsManagement extends Mixins(ApiCall) {
    fileExtensions: FileExtension[] = [];
    fileExtensionToAdd = '';
    errorMessages: string[] = [];
    async mounted(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(): Promise<void> {
        this.apiCall(async () => {
            this.fileExtensions = await getFileExtensions();
        });
    }

    async addNewExtension(): Promise<void> {
        if (!this.validate()){
            return;
        }
        await this.apiCall(async () => {
            const addedExtension = await addFileExtensions(this.fileExtensionToAdd);
            if (addedExtension) {
                this.fileExtensions.push(addedExtension);
                this.fileExtensionToAdd = '';
            }
        });
    }

    async removeExtension(id: number): Promise<void> {
        await this.apiCall(async () => {
            await removeExtension(id);
        });

        await this.fetchData();
    }

    validate(): boolean {
        if (!this.fileExtensionToAdd) {
            this.errorMessages = [];
            return false;
        }
        if (this.fileExtensions.some(x => x.suffix.toUpperCase() === this.fileExtensionToAdd.toUpperCase())){
            this.errorMessages = [this.$t('extensionAlreadyAdded') as string];
            return false;
        }

        const alphanumericRegex = /^[a-zA-Z0-9]*$/;
        if (!this.fileExtensionToAdd.match(alphanumericRegex)) {
            this.errorMessages = [this.$t('onlyAlphanumeric') as string];
            return false;
        }

        this.errorMessages = [];
        return true;
    }
}
