













import {Component, Prop, Vue} from 'vue-property-decorator';
import {NationalHoliday} from './store/NationalHoliday';
import DatePicker from '@/shared/date/DatePicker.vue';

@Component({
    components: {
        DatePicker,
    },
})

export default class NationalHolidaysForm extends Vue {
    @Prop(Object)
    value!: NationalHoliday;

    @Prop(Boolean)
    isEditing!: boolean;

    onInput(field: keyof NationalHoliday, value: NationalHoliday[keyof NationalHoliday]): void {
        this.$emit('input', {
            ...this.value,
            [field]: value,
        });
    }
}
