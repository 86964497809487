


































































import { Project } from '@/dictionaries/projects/store';
import { ApiCall } from '@/shared/mixins/ApiCall';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { editProject } from './store/endpoints';
import AppDialog from '@/shared/AppDialog.vue';
import {InjectShowMessage} from '@/shared/mixins/InjectShowMessage';

@Component({
    components: {
        AppDialog,
    },
})
export default class ProjectsColumnRow extends Mixins(ApiCall, InjectShowMessage) {
    @Prop(String)
    name!: string;

    @Prop(Number)
    id!: number;

    @Prop(Boolean)
    isEnabled!: boolean;

    @Prop(Boolean)
    showDisable!: boolean;

    editing = false;
    newName: string = this.name;
    showToggleModal = false;

    async confirmRename(): Promise<void> {
        if (!this.newName ) {
            return;
        }

        if (this.newName === this.name){
            this.editing = false;
            return;
        }

        const newName = this.newName;

        await this.apiCall(
            async () => await editProject({ projectName: newName, isEnabled: this.isEnabled }, this.id)
        );

        this.$emit('rename', {id: this.id, name: newName} as Project);
        this.editing = false;
    }

    confirmToggle(): void {
        this.toggleProjectAvailability(!this.isEnabled);
        this.showToggleModal = false;
    }

    async toggleProjectAvailability(isEnabled: boolean): Promise<void> {
        await this.apiCall(
            async () => await editProject({ projectName: this.name, isEnabled: isEnabled }, this.id)
        );

        if (this.error) {
            this.showMessage(this.error, 'error');
            return;
        }

        this.$emit('toggle', {id: this.id, isEnabled: isEnabled} as Project);
        this.isEnabled = isEnabled;
    }

    cancel(): void {
        this.newName = this.name;
        this.editing = false;
    }
}
