

















import {Component, Prop, Vue} from 'vue-property-decorator';
import {TranslateResult} from 'vue-i18n';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {NationalHoliday} from '@/system/nationalHoliday/store/NationalHoliday';

@Component({
    components: {
        HmSimpleTable,
    },
})
export default class NationalHolidayList extends Vue {
    @Prop(Array)
    list!: NationalHoliday;

    @Prop(Boolean)
    isFetching!: boolean;

    @Prop(Boolean)
    readOnly!: boolean;

    get headers(): Array<string | TranslateResult> {
        return [
            this.$t('date'),
            this.$t('nationalHolidayName'),
            '',
        ];
    }
}
