







































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {
    createNationalHoliday,
    deleteNationalHoliday,
    listNationalHoliday,
    updateNationalHoliday,
} from '@/system/nationalHoliday/store/endpoints';
import NationalHolidayList from '@/system/nationalHoliday/NationalHolidayList.vue';
import AppDialog from '@/shared/AppDialog.vue';
import NationalHolidayForm from '@/system/nationalHoliday/NationalHolidayForm.vue';
import {
    NationalHoliday,
    NationalHolidayAdd,
    isValidNationalHoliday,
} from '@/system/nationalHoliday/store/NationalHoliday';
import {Nullable} from '@/shared/types/Nullable';

@Component({
    components: {
        NationalHolidayForm,
        AppDialog,
        NationalHolidayList,
        HmSimpleTable,
    },
})
export default class NationalHolidayScreen extends Mixins(ApiCall) {
    @Prop(Boolean)
    readOnly!: boolean;

    nationalHoliday: Array<NationalHoliday> = [];

    nationalHolidayToDelete: Nullable<NationalHoliday> = null;
    nationalHolidayToEdit: Nullable<NationalHoliday | NationalHolidayAdd> = null;
    isEditing = false;

    currentYear = new Date(Date.now()).getFullYear();

    async mounted(): Promise<void> {
        await this.fetchList(this.currentYear);
    }

    async onYearChange(year: number): Promise<void> {
        await this.fetchList(year);
    }

    async fetchList(year: number): Promise<void> {
        await this.apiCall(async () => {
            this.nationalHoliday = await listNationalHoliday(year);
        });
    }

    onDelete(nationalHoliday: NationalHoliday): void {
        this.nationalHolidayToDelete = nationalHoliday;
    }

    async onDeleteConfirm(): Promise<void> {
        const index = this.nationalHoliday.findIndex((nh) => nh.day === this.nationalHolidayToDelete!.day);
        if (index === -1) {
            return;
        }

        await this.apiCall(async () => {
            await deleteNationalHoliday(this.nationalHolidayToDelete!);
        });

        if (!this.error) {
            this.nationalHoliday.splice(index, 1);
        }

        this.nationalHolidayToDelete = null;

        await this.fetchList(this.currentYear);
    }

    onEdit(nationalHoliday: NationalHoliday): void {
        this.nationalHolidayToEdit = nationalHoliday;
        this.isEditing = true;
    }

    onCreate(): void {
        this.nationalHolidayToEdit = {} as NationalHoliday;
        this.isEditing = false;
    }

    async onSaveConfirm(nationalHolidayToSave: NationalHoliday): Promise<void> {
        const index = this.nationalHoliday.findIndex((nh) => nh.day === nationalHolidayToSave.day);
        if (index === -1) {
            return;
        }

        await this.apiCall(async () => {
            await updateNationalHoliday(nationalHolidayToSave);
        });

        if (!this.error) {
            Object.assign(this.nationalHoliday[index], nationalHolidayToSave);
        }

        this.nationalHolidayToEdit = null;
        this.isEditing = false;

        await this.fetchList(this.currentYear);
    }

    async onCreateConfirm(nationalHolidayToAdd: NationalHolidayAdd): Promise<void> {
        const response = await this.apiCall(async () => {
            const res: NationalHoliday = await createNationalHoliday(nationalHolidayToAdd);
        });

        if (!this.error) {
            this.nationalHolidayToEdit = null;
            await this.fetchList(this.currentYear);
        }
    }

    get isEditedNationalHolidayValid(): boolean {
        if (!this.nationalHolidayToEdit) {
            return false;
        }

        return isValidNationalHoliday(this.nationalHolidayToEdit);
    }

    get years(): number[] {
        const date: Date = new Date(Date.now());
        const year = date.getFullYear();
        const tab: number[] = [];
        for(let i = year-6; i<year+2; i++){
            tab.push(i);
        }
        return tab;
    }

    get deleteNationalHolidayConfirmBody(): string {
        if (!this.nationalHolidayToDelete) {
            return '';
        }

        return this.$t('nationalHolidayConfirmDeleteBody', this.nationalHolidayToDelete) as string;
    }
}
