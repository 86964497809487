














import {Component, InjectReactive, Vue} from 'vue-property-decorator';
import AppScreen from '@/layout/AppScreen.vue';
import SyncBtn from '@/system/SyncBtn.vue';
import InvalidateBtn from '@/system/InvalidateBtn.vue';
import HmCol from '@/shared/HmCol.vue';
import BgJobs from '@/system/BgJobs.vue';
import {MeUser} from '@/employees/store/HermesUser';
import ExtensionsManagement from '@/system/ExtensionsManagement.vue';

@Component({
    components: {
        BgJobs,
        HmCol,
        InvalidateBtn,
        SyncBtn,
        AppScreen,
        ExtensionsManagement,
    },
})
export default class SystemScreen extends Vue {
    @InjectReactive()
    viewer!: MeUser;

    mounted(): void {
        if (!this.viewer.isAdmin) {
            this.$router.push({name: 'home'});
        }
    }
}
