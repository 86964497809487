


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Employee } from './store/ProjectsActivities';

@Component
export default class EmployeesColumn extends Vue {
  @Prop(Array)
  employees!: Employee[];


  @Prop(Boolean)
  addingDisabled!: boolean;
}
