

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { StringRecord } from './store/StringEditor';
import AppDialog from '@/shared/AppDialog.vue';

@Component({
    components: {
        AppDialog,
    },
})
export default class InsertRecordModal extends Vue {

    @Prop(Boolean)
    show!: boolean;

    key = '';
    polish ='';
    english = '';

    insertRecord(): void {
        if (!this.key){
            return;
        }

        const toInsert: StringRecord = {
            key : this.key,
            polish : this.polish,
            english : this.english,
        };
        this.$emit('insert', toInsert);
    }
}

