





























import
{Component, Prop, Vue} from 'vue-property-decorator';
import {TranslateResult} from 'vue-i18n';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {TimesheetSchedule} from '@/system/timesheetSchedule/store/TimesheetSchedule';
import {formatDate} from '@/shared/date/formatDate';

@Component({
    components: {
        HmSimpleTable,
    },
    filters: {
        formatDate,
    },
})
export default class TimesheetSchedulesList extends Vue {
    @Prop(Array)
    list!: TimesheetSchedule[];

    @Prop(Boolean)
    isFetching!: boolean;

    @Prop(Boolean)
    readOnly!: boolean;

    get headers(): Array<string | TranslateResult> {
        return [
            this.$t('id'),
            this.$t('name'),
            this.$t('hoursPerDay'),
            this.$t('startDate'),
            this.$t('endDate'),
            this.$t('includeBankHolidays'),
            '',
        ];
    }
}
