


















import {Component, Mixins} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {getBgJobs} from '@/system/store/endpoints';

@Component
export default class BgJobs extends Mixins(ApiCall) {
    bgJobs: object[] = [];

    async mounted(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(): Promise<void> {
        this.apiCall(async () => {
            this.bgJobs = await getBgJobs();
        });
    }
}
