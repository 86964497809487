




















































import debounce from 'lodash.debounce';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Activity } from './store/ProjectsActivities';
import draggable from 'vuedraggable';
import ActivitiesColumnRow from './ActivitiesColumnRow.vue';

@Component({
    components: {
        draggable,
        ActivitiesColumnRow,
    },
})
export default class ActivitiesColumn extends Vue {

    @Prop(Number)
    value!: number

    @Prop(Array)
    activities!: Activity[];

    @Prop(Boolean)
    addingDisabled!: boolean;

    updateOrderDebounced = debounce((event) => this.$emit('orderUpdated', event), 1000);
    showDisable = false;

    onVisibilityChange(): void {
        this.showDisable = !this.showDisable;
    }
}

