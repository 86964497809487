import {ID, AbstractEntity} from '@/shared/store/AbstractEntity';

export interface TimesheetSchedule extends AbstractEntity {
    id: ID;
    name: string;
    hoursPerDay: number;
    startDate: Date;
    endDate: Date;
    includeBankHolidays: boolean;
}

export type TimesheetScheduleAdd = Omit<TimesheetSchedule, 'id'> &{
    startDate: Date;
    endDate: Date;
};

export function isValidTimesheetSchedule(value: TimesheetSchedule | TimesheetScheduleAdd): boolean {
    if (value.startDate > new Date(1, 1, 1) ||
        value.endDate > new Date(1, 1, 1)) {

        return value.name != undefined &&
            value.name !== '' &&
            value.endDate > value.startDate;
    } else {
        return value.name != undefined &&
            value.name !== '';
    }
}
