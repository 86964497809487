import {api} from '@/shared/api';
import { Language, StringRecordUpdate } from './StringEditor';

export async function getStringList(): Promise<Record<string, Language>> {
    const {data} = await api<Record<string, Language>>({
        method: 'get',
        url: 'system/stringeditor',
    });

    return data;
}

export async function insertOrUpdateRecord(payload: StringRecordUpdate): Promise<void> {
    await api({
        method: 'post',
        url: 'system/stringeditor',
        data: payload,
    });
}

export async function renameRecord(oldKey: string, newKey: string): Promise<void> {
    await api({
        method: 'post',
        url: `system/stringeditor/${oldKey}`,
        data: {
            newKey,
        },
    });
}


export async function deleteRecord(key: string): Promise<void> {
    await api({
        method: 'delete',
        url: `system/stringeditor/${key}`,
    });
}


