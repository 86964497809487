





































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {
    DocumentData,
    EzlaDocument,
    EzlaDocumentItem,
    InsuredData,
} from '@/system/fakeZusSynchronization/store/EzlaDocument';
import AppScreen from '@/layout/AppScreen.vue';
import HashId from '@/shared/HashId.vue';
import FakeZusSynchronizationForm from '@/system/fakeZusSynchronization/FakeZusSynchronizationForm.vue';
import {applyZlaDocuments} from '@/system/fakeZusSynchronization/store/endpoints';
import {MessageColor} from '@/shared/mixins/InjectShowMessage';

@Component({
    components: {
        FakeZusSynchronizationForm,
        HashId,
        AppScreen,
    },
})
export default class FakeZusSynchronizationScreen extends Mixins(ApiCall) {
    itemsCount = 0;
    color: MessageColor = 'success';
    displaySnackbar = false;
    loading = false;
    newDocument = {documentData: {periodInHospital: {}, series: 'XYZ', number: '123', absencePeriod: {dateFromString: '2023-12-20', dateToString: '2023-12-22'}} as DocumentData, insuredData: {identifier: {type: 0, value: '9505315698'}} as InsuredData} as EzlaDocument;

    ezlaDocumentsList: EzlaDocumentItem[] = [{
        id: this.itemsCount,
        ezlaDocument: {...this.newDocument} as EzlaDocument,
    }];

    async AddDocument(): Promise<void> {
        this.ezlaDocumentsList = [...this.ezlaDocumentsList, {
            id: ++this.itemsCount,
            ezlaDocument: {...this.newDocument} as EzlaDocument,
        }];
    }

    async DeleteDocument(id: number): Promise<void> {
        this.ezlaDocumentsList = this.ezlaDocumentsList.filter(document => document.id !== id);
    }

    async ApplyDocuments(): Promise<void> {
        this.loading = true;
        let resp = false;

        try {
            await this.apiCall(async () => {
                resp = await applyZlaDocuments(this.ezlaDocumentsList.map(x => x.ezlaDocument));
            });
        } catch(err){
            console.error(err);
        } finally {
            this.color = resp ? 'success' : 'error';
            this.displaySnackbar = true;
        }

        this.loading = false;
    }
}
