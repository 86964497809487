import { render, staticRenderFns } from "./ExtensionsManagement.vue?vue&type=template&id=313d7e9a&scoped=true&"
import script from "./ExtensionsManagement.vue?vue&type=script&lang=ts&"
export * from "./ExtensionsManagement.vue?vue&type=script&lang=ts&"
import style0 from "./ExtensionsManagement.vue?vue&type=style&index=0&id=313d7e9a&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "313d7e9a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VChip,VChipGroup,VIcon,VProgressLinear,VSheet,VTextField})
