

























import {Component, Prop, Vue} from 'vue-property-decorator';
import {TranslateResult} from 'vue-i18n';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {LeaveRequestTypeSpecificFields, LeaveRequestTypeSpecificFieldsEnum} from './store/LeaveRequestTypeSpecificFields';

@Component({
    components: {
        HmSimpleTable,
    },
})
export default class LeaveRequestTypeSpecificFieldsList extends Vue {
    @Prop(Array)
    list!: LeaveRequestTypeSpecificFields;

    @Prop(Boolean)
    isFetching!: boolean;

    @Prop(Boolean)
    readOnly!: boolean;

    protected fieldTypesEnum = LeaveRequestTypeSpecificFieldsEnum;

    get headers(): Array<string | TranslateResult> {
        return [
            this.$t('leaveRequestTypeSpecificFieldsLeaveSubtypeName'),
            this.$t('leaveRequestTypeSpecificFieldsLeaveSubtype'),
            this.$t('leaveRequestTypeSpecificFieldsName'),
            this.$t('leaveRequestTypeSpecificFieldsType'),
            this.$t('leaveRequestTypeSpecificFieldsOptions'),
            this.$t('leaveRequestTypeSpecificFieldsRequired'),
            '',
        ];
    }
}
