



























import {Component, Prop, Vue} from 'vue-property-decorator';
import {TimesheetSchedule, TimesheetScheduleAdd} from './store/TimesheetSchedule';
import DatePicker from '@/shared/date/DatePicker.vue';
import {Nullable} from '@/shared/types/Nullable';

@Component({
    components: {
        DatePicker,
    },
})

export default class TimesheetScheduleForm extends Vue {
    @Prop(Object)
    value!: (TimesheetSchedule & TimesheetScheduleAdd);

    startDate: Nullable<Date> = null;
    endDate: Nullable<Date> = null;

    includeBankHolidays = false;

    ruleEndDateInFuture(value: Date): string | boolean {
        if (!this.startDate) {
            return true;
        }
        return value <= this.startDate! ? this.$t('startDateExceedsEndDate') as string : true;
    }

    onInput(field: keyof TimesheetScheduleAdd, value: TimesheetScheduleAdd[keyof TimesheetScheduleAdd]): void {
        this.$emit('input', {
            ...this.value,
            [field]: value,
        });
    }
}
