


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Project } from './store/ProjectsActivities';
import ProjectsColumnRow from './ProjectsColumnRow.vue';

@Component({
    components: {
        ProjectsColumnRow,
    },
})
export default class ProjectsColumn extends Vue {

    @Prop(Array)
    projects!: Project[];

    @Prop(Number)
    value!: number

    searchText = '';
    searchBoxClosed = true;
    showDisable = false;

    get projectsFiltered(): Project[] {
        if (this.searchText) {
            return this.projects.filter(proj => proj.name.toLowerCase().includes(this.searchText.toLowerCase()));
        }
        return this.projects;
    }

    onVisibilityChange(): void {
        this.showDisable = !this.showDisable;
    }
}

