



























































import {Component, Mixins, Prop} from 'vue-property-decorator';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {
    createTimesheetSchedule,
    deleteTimesheetSchedule,
    listTimesheetSchedules,
    updateTimesheetSchedule,
} from '@/system/timesheetSchedule/store/endpoints';
import TimesheetScheduleList from '@/system/timesheetSchedule/TimesheetScheduleList.vue';
import AppDialog from '@/shared/AppDialog.vue';
import TimesheetScheduleForm from '@/system/timesheetSchedule/TimesheetScheduleForm.vue';
import {
    TimesheetSchedule,
    TimesheetScheduleAdd,
    isValidTimesheetSchedule,
} from '@/system/timesheetSchedule/store/TimesheetSchedule';
import {Nullable} from '@/shared/types/Nullable';
import {InjectShowMessage} from '@/shared/mixins/InjectShowMessage';

@Component({
    components: {
        TimesheetScheduleForm,
        AppDialog,
        TimesheetScheduleList,
        HmSimpleTable,
    },
})
export default class TimesheetScheduleScreen extends Mixins(ApiCall, InjectShowMessage) {
    @Prop(Boolean)
    readOnly!: boolean;

    isFetching = false;

    timesheetSchedules: Array<TimesheetSchedule> = [];

    timesheetScheduleToDelete: Nullable<TimesheetSchedule> = null;
    timesheetScheduleToEdit: Nullable<TimesheetSchedule | TimesheetScheduleAdd> = null;

    async mounted(): Promise<void> {
        await this.fetchList();
    }

    async fetchList(): Promise<void> {
        await this.apiCall(async () => {
            this.timesheetSchedules = await listTimesheetSchedules();
        });
        this.isFetching = false;
    }

    onDelete(timesheetSchedule: TimesheetSchedule): void {
        this.timesheetScheduleToDelete = timesheetSchedule;
    }

    async onDeleteConfirm(): Promise<void> {
        const index = this.timesheetSchedules.findIndex((ts) => ts.id === this.timesheetScheduleToDelete?.id);
        if (index === -1) {
            return;
        }

        this.isFetching = true;
        this.showMessage(this.$t('operationInProgress'), 'info');

        await this.apiCall(async () => {
            await deleteTimesheetSchedule(this.timesheetScheduleToDelete!);
        });

        if (!this.error) {
            this.timesheetSchedules.splice(index, 1);
            await this.fetchList();
        }

        await this.invokeSuccessOrErrorMessage();

        this.timesheetScheduleToDelete = null;
    }

    onEdit(timesheetSchedule: TimesheetSchedule): void {
        this.timesheetScheduleToEdit = timesheetSchedule;
    }

    async onSaveConfirm(timesheetScheduleToSave: TimesheetSchedule): Promise<void> {
        const index = this.timesheetSchedules.findIndex((ts) => ts.id === timesheetScheduleToSave.id);
        if (index === -1) {
            return;
        }

        this.isFetching = true;
        this.showMessage(this.$t('operationInProgress'), 'info');

        await this.apiCall(async () => {
            await updateTimesheetSchedule(timesheetScheduleToSave);
        });

        if (!this.error) {
            Object.assign(this.timesheetSchedules[index], timesheetScheduleToSave);
        }

        await this.invokeSuccessOrErrorMessage();

        this.timesheetScheduleToEdit = null;

        await this.fetchList();
    }

    async onCreateConfirm(timesheetScheduleToAdd: TimesheetScheduleAdd): Promise<void> {
        this.isFetching = true;
        this.showMessage(this.$t('operationInProgress'), 'info');

        await this.apiCall(async () => {
            await createTimesheetSchedule(timesheetScheduleToAdd);
        });

        await this.invokeSuccessOrErrorMessage();

        this.timesheetScheduleToEdit = null;

        await this.fetchList();
    }

    get isEditedtimesheetScheduleValid(): boolean {
        if (!this.timesheetScheduleToEdit) {
            return false;
        }

        return isValidTimesheetSchedule(this.timesheetScheduleToEdit);
    }

    get deleteTimesheetScheduleConfirmBody(): string {
        if (!this.timesheetScheduleToDelete) {
            return '';
        }

        return this.$t('timesheetSchedulesConfirmDeleteBody', this.timesheetScheduleToDelete) as string;
    }

    async invokeSuccessOrErrorMessage(): Promise<void> {
        if (!this.error) {
            this.showMessage(this.$t('operationSucceed'), 'success');
        } else {
            this.showMessage(this.$t('somethingWentWrong'), 'error');
        }
    }
}
