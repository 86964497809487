import {api} from '@/shared/api';
import { Activity, ActivityAddModel, Project, ProjectAddModel } from './ProjectsActivities';

export async function getProjectsWithActivitiesAndUsers(): Promise<Project[]> {
    const {data} = await api<Project[]>({
        method: 'get',
        url: 'projects',
    });

    return data;
}

export async function addProject(payload: ProjectAddModel): Promise<number> {
    const {data} = await api<number>({
        method: 'post',
        url: 'projects',
        data: payload,
    });

    return data;
}

export async function editProject(payload: ProjectAddModel, projectId: number): Promise<void> {
    await api({
        method: 'put',
        url: `projects/${projectId}`,
        data: payload,
    });
}

export async function addActivity(payload: ActivityAddModel, projectId: number): Promise<number> {
    const {data} = await api<number>({
        method: 'post',
        url: `projects/${projectId}/activities`,
        data: payload,
    });

    return data;
}

export async function editActivity(payload: ActivityAddModel, activityId: number): Promise<void> {
    await api({
        method: 'put',
        url: `activities/${activityId}`,
        data: payload,
    });
}

export async function removeActivity(activityId: number): Promise<void> {
    await api({
        method: 'delete',
        url: `activities/${activityId}`,
    });
}

export async function assignEmployee(activityId: number, employeeId: number): Promise<void> {
    await api({
        method: 'post',
        url: `activities/${activityId}/${employeeId}`,
    });
}

export async function removeEmployee(activityId: number, employeeId: number): Promise<void> {
    await api({
        method: 'delete',
        url: `activities/${activityId}/${employeeId}`,
    });
}

export async function updateActivitiesOrder(payload: Activity[], projectId: number): Promise<void> {
    await api({
        method: 'put',
        url: `projects/${projectId}/activities`,
        data: payload,
    });
}
