

























import { Component, Mixins } from 'vue-property-decorator';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {TranslateResult} from 'vue-i18n';
import { ApiCall } from '@/shared/mixins/ApiCall';
import { StringRecord, StringRecordUpdate, Locale } from './store/StringEditor';
import { deleteRecord, getStringList, insertOrUpdateRecord } from './store/endpoints';
import StringEditorRow from './StringEditorRow.vue';
import InsertRecordModal from './InsertRecordModal.vue';
import ConfirmRecordDeletionModal from './ConfirmRecordDeletionModal.vue';
import AppScreen from '@/layout/AppScreen.vue';
import { listLeaveRequestTypeSpecificFields } from '@/system/leaveRequestTypeSpecificFields/store/endpoints';
import htmlEnabledKeys from '../assets/htmlEnabledStrings.json';
import { InjectShowMessage } from '@/shared/mixins/InjectShowMessage';

@Component({
    components: {
        HmSimpleTable,
        StringEditorRow,
        InsertRecordModal,
        ConfirmRecordDeletionModal,
        AppScreen,
    },
})
export default class StringEditor extends Mixins(ApiCall, InjectShowMessage) {

    get headers(): Array<string | TranslateResult> {
        return [
            this.$t('stringDictionaryKey') as string,
            this.$t('stringDictionaryPolish') as string,
            this.$t('stringDictionaryEnglish') as string,
        ];
    }

    list: StringRecord[] = [];
    leaveRequestTypeSpecificFields: string[] = [];
    recordToDelete = '';
    insertingRecord = false;

    async mounted(): Promise<void> {
        this.leaveRequestTypeSpecificFields = (await listLeaveRequestTypeSpecificFields())?.map(x => x.fieldName);
        await this.fetchList();
    }

    async fetchList(): Promise<void> {

        await this.apiCall(async () => {
            const dictionary = await getStringList();
            this.list = Object.keys(dictionary).map(key => ({
                key,
                polish: dictionary[key].polish,
                english: dictionary[key].english,
                htmlEnabled: htmlEnabledKeys.includes(key) || this.leaveRequestTypeSpecificFields.includes(key),
            } as StringRecord)).sort((a, b) => a.key.localeCompare(b.key));
        });
    }

    async handleUpdate(key: string, locale: Locale): Promise<void> {
        const payload: StringRecordUpdate = {
            key: key,
            locales: [locale],
        };

        await this.apiCall(async () => {
            await insertOrUpdateRecord(payload);
        });
        await this.fetchList();
    }

    handleDelete(key: string): void {
        this.recordToDelete = key;
    }

    async deleteConfirmed(): Promise<void> {
        await this.apiCall(async () => {
            await deleteRecord(this.recordToDelete);
        });
        this.recordToDelete = '';
        await this.fetchList();
    }

    async handleInsert(toInsert: StringRecord): Promise<void> {
        if (this.list.some(x => x.key === toInsert.key)) {
            this.showMessage('String already exists', 'error');
            return;
        }

        const payload: StringRecordUpdate = {
            key: toInsert.key,
            locales: [
                {
                    locale: 'pl',
                    value: toInsert.polish,
                },
                {
                    locale: 'en',
                    value: toInsert.english,
                },
            ],
        };

        this.apiCall(async () => {
            await insertOrUpdateRecord(payload);
        });

        this.insertingRecord = false;
        await this.fetchList();
    }

}

