



























































import {Component, Mixins, Prop} from 'vue-property-decorator';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {
    createLeaveRequestTypeSpecificField,
    deleteLeaveRequestTypeSpecificField,
    listLeaveRequestTypeSpecificFields,
    updateLeaveRequestTypeSpecificField,
} from '@/system/leaveRequestTypeSpecificFields/store/endpoints';
import LeaveRequestTypeSpecificFieldsList
    from '@/system/leaveRequestTypeSpecificFields/LeaveRequestTypeSpecificFieldsList.vue';
import AppDialog from '@/shared/AppDialog.vue';
import LeaveRequestTypeSpecificFieldsForm
    from '@/system/leaveRequestTypeSpecificFields/LeaveRequestTypeSpecificFieldsForm.vue';
import {
    isValidLeaveRequestTypeSpecificField,
    LeaveRequestTypeSpecificFieldAdd,
    LeaveRequestTypeSpecificFields,
} from '@/system/leaveRequestTypeSpecificFields/store/LeaveRequestTypeSpecificFields';
import {Nullable} from '@/shared/types/Nullable';
import { insertOrUpdateRecord, renameRecord } from '@/stringEditor/store/endpoints';
import { StringRecordUpdate } from '@/stringEditor/store/StringEditor';

@Component({
    components: {
        LeaveRequestTypeSpecificFieldsForm,
        AppDialog,
        LeaveRequestTypeSpecificFieldsList,
        HmSimpleTable,
    },
})
export default class LeaveRequestTypeSpecificFieldsScreen extends Mixins(ApiCall) {
    @Prop(Boolean)
    readOnly!: boolean;

    leaveRequestTypeSpecificFields: Array<LeaveRequestTypeSpecificFields> = [];

    leaveRequestTypeSpecificFieldsToDelete: Nullable<LeaveRequestTypeSpecificFields> = null;
    leaveRequestTypeSpecificFieldsToEdit: Nullable<LeaveRequestTypeSpecificFields | LeaveRequestTypeSpecificFieldAdd> = null;
    isEditing = false;


    async mounted(): Promise<void> {
        await this.fetchList();
    }

    async fetchList(): Promise<void> {
        await this.apiCall(async () => {
            this.leaveRequestTypeSpecificFields = await listLeaveRequestTypeSpecificFields();
        });
    }

    onDelete(leaveRequestTypeSpecificFields: LeaveRequestTypeSpecificFields): void {
        this.leaveRequestTypeSpecificFieldsToDelete = leaveRequestTypeSpecificFields;
    }

    async onDeleteConfirm(): Promise<void> {
        const index = this.leaveRequestTypeSpecificFields.findIndex((nh) => nh.id === this.leaveRequestTypeSpecificFieldsToDelete!.id);
        if (index === -1) {
            return;
        }

        await this.apiCall(async () => {
            await deleteLeaveRequestTypeSpecificField(this.leaveRequestTypeSpecificFieldsToDelete!.id);
        });

        if (!this.error) {
            this.leaveRequestTypeSpecificFields.splice(index, 1);
        }

        this.leaveRequestTypeSpecificFieldsToDelete = null;

        await this.fetchList();
    }

    onEdit(leaveRequestTypeSpecificFields: LeaveRequestTypeSpecificFields): void {
        this.leaveRequestTypeSpecificFieldsToEdit = leaveRequestTypeSpecificFields;
        this.isEditing = true;
    }

    onCreate(): void {
        this.leaveRequestTypeSpecificFieldsToEdit = {} as LeaveRequestTypeSpecificFields;
        this.isEditing = false;
    }

    async onSaveConfirm(leaveRequestTypeSpecificFieldsToSave: LeaveRequestTypeSpecificFields): Promise<void> {
        const index = this.leaveRequestTypeSpecificFields.findIndex((nh) => nh.id === leaveRequestTypeSpecificFieldsToSave.id);
        if (index === -1) {
            return;
        }

        await this.apiCall(async () => {
            await updateLeaveRequestTypeSpecificField(leaveRequestTypeSpecificFieldsToSave);
        });

        if (!this.error) {
            const oldName = this.leaveRequestTypeSpecificFields[index].fieldName;
            const newName = leaveRequestTypeSpecificFieldsToSave.fieldName;
            if (oldName !== newName) {
                await  this.apiCall(async () => {
                    await renameRecord(oldName, newName);
                });
            }

            Object.assign(this.leaveRequestTypeSpecificFields[index], leaveRequestTypeSpecificFieldsToSave);
        }

        this.leaveRequestTypeSpecificFieldsToEdit = null;
        this.isEditing = false;

        await this.fetchList();
    }

    async onCreateConfirm(leaveRequestTypeSpecificFieldsToAdd: LeaveRequestTypeSpecificFieldAdd): Promise<void> {
        await this.apiCall(async () => {
            await createLeaveRequestTypeSpecificField(leaveRequestTypeSpecificFieldsToAdd);
        });

        if (!this.error) {
            const payload: StringRecordUpdate = {
                key: leaveRequestTypeSpecificFieldsToAdd.fieldName,
                locales: [
                    {
                        locale: 'pl',
                        value: '',
                    },
                    {
                        locale: 'en',
                        value: '',
                    },
                ],
            };

            await  this.apiCall(async () => {
                await insertOrUpdateRecord(payload);
            });

            this.leaveRequestTypeSpecificFieldsToEdit = null;
            await this.fetchList();
        }
    }

    get isEditedLeaveRequestTypeSpecificFieldsValid(): boolean {
        if (!this.leaveRequestTypeSpecificFieldsToEdit) {
            return false;
        }

        return isValidLeaveRequestTypeSpecificField(this.leaveRequestTypeSpecificFieldsToEdit);
    }

    get deleteLeaveRequestTypeSpecificFieldsConfirmBody(): string {
        if (!this.leaveRequestTypeSpecificFieldsToDelete) {
            return '';
        }

        return this.$t('leaveRequestTypeSpecificFieldsConfirmDeleteBody', this.leaveRequestTypeSpecificFieldsToDelete) as string;
    }
}
