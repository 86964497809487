



















import {Component, Mixins} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {SystemBtnMixin} from '@/system/shared/SystemBtnMixin';
import {invalidateCache} from '@/system/store/endpoints';

@Component
export default class InvalidateBtn extends Mixins(ApiCall, SystemBtnMixin) {
    async onClick(): Promise<void> {
        await this.apiCall(invalidateCache);
    }
}
