

















































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {
    EzlaDocumentItem,
} from '@/system/fakeZusSynchronization/store/EzlaDocument';
import AppScreen from '@/layout/AppScreen.vue';

@Component({
    components: {
        AppScreen,
    },
})
export default class FakeZusSynchronizationForm extends Mixins(ApiCall) {
    @Prop(Object)
    ezlaDocumentItem!: EzlaDocumentItem;

    async DeleteDocument(): Promise<void> {
        this.$emit('delete', this.ezlaDocumentItem.id);
    }
}
