

























































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {LeaveRequestTypeSpecificFields, LeaveRequestTypeSpecificFieldsEnum} from './store/LeaveRequestTypeSpecificFields';
import {listLeaveSubtypes} from '@/system/leaveRequestTypeSpecificFields/store/endpoints';
import {ApiCall} from '@/shared/mixins/ApiCall';

@Component({
    components: {
    },
})

export default class LeaveRequestTypeSpecificFieldsForm extends Mixins(ApiCall) {
    @Prop(Object)
    value!: LeaveRequestTypeSpecificFields;

    @Prop(Boolean)
    isEditing!: boolean;

    leaveSubtypes: {
        id: number;
        displayText: string;
    }[] = [];

    fieldTypesEnums: {
        id: number;
        displayText: string;
    }[] = [];

    newOption = '';

    protected fieldTypesEnum = LeaveRequestTypeSpecificFieldsEnum;

    async mounted(): Promise<void> {
        await this.listSubtypes();
        await this.mapEnumToFieldType();
    }

    async listSubtypes(): Promise<void>{
        await this.apiCall(async () => {
            const subtypes = await listLeaveSubtypes();
            this.leaveSubtypes = subtypes.map((subtype) => ({id: subtype.id, displayText: `${subtype.id}. ${subtype.name}`}));
        });
    }

    async mapEnumToFieldType(): Promise<void> {
        for (const fieldType in LeaveRequestTypeSpecificFieldsEnum) {
            if (!isNaN(Number(fieldType))) {
                const id = +fieldType;
                const displayText = LeaveRequestTypeSpecificFieldsEnum[id];
                this.fieldTypesEnums.push({ id, displayText });
            }
        }
    }

    onInput(field: keyof LeaveRequestTypeSpecificFields, value: LeaveRequestTypeSpecificFields[keyof LeaveRequestTypeSpecificFields]): void {
        this.$emit('input', {
            ...this.value,
            [field]: value,
        });
    }

    addOption(): void {
        const options = this.value.fieldOptions || []; // utwórz nową listę, jeśli nie istnieje
        if (!options.includes(this.newOption)) {
            this.onInput('fieldOptions', [...options, this.newOption]);
        }
        this.newOption = '';
    }

    deleteOption(index: number): void {
        const fieldOptions = [...this.value.fieldOptions];
        fieldOptions.splice(index, 1);
        this.onInput('fieldOptions', fieldOptions);
    }
}
