import {Component, Vue} from 'vue-property-decorator';
import {Nullable} from '@/shared/types/Nullable';

@Component({})
export class SystemBtnMixin extends Vue {
    error!: Nullable<string>;

    get btnColor(): string {
        if (this.error) {
            return 'error';
        } else {
            return '';
        }
    }
}
