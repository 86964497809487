


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { StringRecord, Locale } from './store/StringEditor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { quillEditor } from 'vue-quill-editor';

@Component({
    components: {
        quillEditor,
    },
})
export default class StringEditorRow extends Vue {
  @Prop(Object)
  record!: StringRecord;

  editingPolish = false;
  editingEnglish = false;
  polishValue = this.record.polish;
  englishValue = this.record.english;
  editorOptions = {
      modules: {
          toolbar: [
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [{ header: [1, 2, 3, 4, 5, false] }],
              ['link', 'video'],
              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              ['clean'],
          ],
      }};

  discardPolishChanges(): void {
      this.polishValue = this.record.polish;
      this.editingPolish = false;
  }

  discardEnglishChanges(): void {
      this.englishValue = this.record.english;
      this.editingEnglish = false;
  }

  polishSaved(): void {
      this.$emit('updated', {
          locale: 'pl',
          value: this.polishValue,
      } as Locale);
      this.editingPolish = false;
  }

  englishSaved(): void {
      this.$emit('updated', {
          locale: 'en',
          value: this.englishValue,
      } as Locale);
      this.editingEnglish = false;
  }
}
