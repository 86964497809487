import {AbstractEntity} from '@/shared/store/AbstractEntity';

export interface NationalHoliday extends AbstractEntity {
    name: string;
    day: Date;
}

export type NationalHolidayAdd = Omit<NationalHoliday, 'id'>;

export function isValidNationalHoliday(value: NationalHoliday | NationalHolidayAdd): boolean {
    return value.name !== '' && value.name !== undefined && value.day !== null && value.day !== undefined;
}
