
































import { Component, Mixins, Watch } from 'vue-property-decorator';
import HmSimpleTable from '@/shared/HmSimpleTable.vue';
import AppScreen from '@/layout/AppScreen.vue';
import { Activity, Employee, Project } from './store/ProjectsActivities';
import { getProjectsWithActivitiesAndUsers, updateActivitiesOrder, removeEmployee, assignEmployee, addActivity, addProject, removeActivity } from './store/endpoints';
import Modal from './ProjectsActivitiesModal.vue';
import EmployeesColumn from './EmployeesColumn.vue';
import ActivitiesColumn from './ActivitiesColumn.vue';
import ProjectsColumn from './ProjectsColumn.vue';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {getManageableEmployees} from '@/employees/store/endpoints';
import { HermesUser } from '@/employees/store/HermesUser';
import { InjectShowMessage } from '@/shared/mixins/InjectShowMessage';

@Component({
    components: {
        HmSimpleTable,
        AppScreen,
        Modal,
        EmployeesColumn,
        ActivitiesColumn,
        ProjectsColumn,
    },
})

export default class ProjectsActivitiesScreen extends Mixins(ApiCall, InjectShowMessage) {
    projects: Project[] = [];
    selectedProject: number | null = null;
    selectedActivity: number | null = null;
    showAddProjectModal = false;
    showAddActivityModal = false;
    showAddEmployeeModal = false;
    allEmployees: HermesUser[] = [];
    employeeToAdd: number | null = null;
    newActivityName: string | null = null;
    newProjectName: string | null = null;

    async mounted(): Promise<void> {
        await this.apiCall(async () => {
            this.projects = await getProjectsWithActivitiesAndUsers();
            this.allEmployees = await getManageableEmployees();
        });
    }

    get activities(): Activity[] {
        if (this.selectedProject){
            return this.projects.find(p => p.id === this.selectedProject)?.activities ?? [];
        }
        return [];
    }

    set activities(value){
        if (this.selectedProject != null){
            this.projects = this.projects.map(item => {
                if (this.selectedProject == item.id){
                    return {...item, activities: value};
                }
                return item;
            });
        }
    }

    get activityEmployees(): Employee[] {
        if (this.selectedProject && this.selectedActivity) {
            return this.activities.find(a => a.id === this.selectedActivity)?.employees ?? [];
        }

        return [];
    }

    get employeesDropdown(): HermesUser[] {
        return this.allEmployees.filter(x => !this.activityEmployees.find(y => y.id === x.id));
    }

    @Watch('selectedProject')
    onSelectedProjectChange(): void {
        this.selectedActivity = null;
    }

    @Watch('error')
    onError(): void {
        this.showMessage(this.error, 'error');
    }

    async updateItemOrder(): Promise<void> {
        if (!this.selectedProject) {
            return;
        }

        const projectId = this.selectedProject;

        const items = this.activities.map((item, index) => ({
            ...item, order: index,
        } as Activity));

        await this.apiCall(async () => await updateActivitiesOrder(items, projectId));

        this.showMessage(this.$t('orderUpdated'), 'success');
    }

    renameProject(project: Project): void {
        const {id, name} = project;

        this.projects = this.projects.map(project => {
            if (project.id === id){
                return {...project, name: name};
            }
            return project;
        });
    }

    toggleProject(project: Project): void {
        const {id, isEnabled} = project;

        this.projects = this.projects.map(project => {
            if (project.id === id){
                const updatedActivities = project.activities.map(activity => ({
                    ...activity,
                    isEnabled: isEnabled ? activity.isEnabled : false,
                    employees: isEnabled ? activity.employees : [],
                }));

                return {...project, isEnabled: isEnabled, activities: updatedActivities};
            }
            return project;
        });
    }

    renameActivity(activity: Activity): void {
        const {id, name} = activity;

        this.activities = this.activities.map(activity => {
            if (activity.id === id){
                return {...activity, name: name};
            }
            return activity;
        });
    }

    async removeActivity(activityId: number): Promise<void>{
        await this.apiCall(async () => await removeActivity(activityId));

        if (this.error) {
            return;
        }

        this.activities = this.activities.filter(a => a.id !== activityId);
    }

    toggleActivity(activity: Activity): void {
        const {id, isEnabled} = activity;

        this.activities = this.activities.map(activity => {
            if (activity.id === id){
                return {...activity, isEnabled: isEnabled, employees: []};
            }
            return activity;
        });
    }

    async removeEmployee(employeeId: number): Promise<void>{
        if (!this.selectedActivity) {
            return;
        }

        const activityId = this.selectedActivity;

        await this.apiCall(async () => await removeEmployee(activityId, employeeId));

        if (this.error) {
            return;
        }

        this.activities = this.activities.map(activity => {
            if (activity.id == activityId){
                return {...activity, employees: this.activityEmployees.filter(employee => employee.id !== employeeId)};
            }
            return activity;
        });
    }

    async addProject(): Promise<void> {
        if (!this.newProjectName) {
            return;
        }

        const projectName = this.newProjectName;
        let newProjectId = 0;

        await this.apiCall(async () => {
            newProjectId = await addProject({projectName: projectName, isEnabled: true});
        });

        if (this.error) {
            return;
        }

        this.projects = this.projects.concat({id: newProjectId, name: projectName, isEnabled: true} as Project);

        this.newProjectName = null;
        this.showAddProjectModal = false;
    }

    async addActivity(): Promise<void> {
        if (!this.selectedProject || !this.newActivityName) {
            return;
        }

        const projectId = this.selectedProject;
        const activityName = this.newActivityName;
        let newActivityId = 0;

        await this.apiCall(async () => {
            newActivityId = await addActivity({activityName: activityName, isEnabled: true}, projectId);
        });

        if (this.error) {
            return;
        }

        this.activities = this.activities.concat({id: newActivityId, name: activityName, isEnabled: true} as Activity);

        this.newActivityName = null;
        this.showAddActivityModal = false;
    }

    async assignEmployee(): Promise<void> {
        if (this.employeeToAdd === null || !this.selectedActivity) {
            return;
        }

        const employeeId = this.employeeToAdd;
        const activityId = this.selectedActivity;

        await this.apiCall(async () => await assignEmployee(activityId, employeeId));

        if (this.error) {
            return;
        }

        const newEmployee = this.employeesDropdown.find(x => x.id == employeeId);
        const newEmployees = this.activityEmployees.concat({id: newEmployee?.id, fullName: newEmployee?.fullName} as Employee);

        this.activities = this.activities.map(activity => {
            if (activity.id == activityId){
                return {...activity, employees: newEmployees};
            }
            return activity;
        });

        this.employeeToAdd = null;
        this.showAddEmployeeModal = false;
    }

    get addActivityDisabled(): boolean{
        return !this.selectedProject;
    }

    get addEmployeeDisabled(): boolean {
        if (!this.selectedProject || !this.selectedActivity) {
            return true;
        }

        const selectedActivity = this.activities.find(a => a.id === this.selectedActivity);

        return selectedActivity ? !selectedActivity.isEnabled : true;
    }
}

