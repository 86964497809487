import {api} from '@/shared/api';
import {EzlaDocument} from '@/system/fakeZusSynchronization/store/EzlaDocument';

export async function applyZlaDocuments(payload: EzlaDocument[]): Promise<boolean> {
    const response = await api<string>({
        method: 'post',
        url: 'system/fakeZusSynchronization/applyZlaDocuments',
        data: payload,
    });

    return response.status == 200;
}
