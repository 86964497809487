import {NationalHoliday, NationalHolidayAdd} from '@/system/nationalHoliday/store/NationalHoliday';
import {api} from '@/shared/api';

export async function listNationalHoliday(year: number): Promise<NationalHoliday[]> {
    const {data} = await api<NationalHoliday[]>({
        method: 'get',
        url: `Timesheets/holidays/${year}`,
    });

    return data;
}

export async function createNationalHoliday(nationalHoliday: NationalHolidayAdd): Promise<NationalHoliday> {
    const {data} = await api<NationalHoliday>({
        method: 'post',
        url: 'Timesheets/holidays',
        data: nationalHoliday,
    });

    return data;
}

export async function deleteNationalHoliday(nationalHoliday: NationalHoliday): Promise<void> {
    await api<NationalHoliday>({
        method: 'delete',
        url: `Timesheets/holidays/${nationalHoliday.day}`,
    });
}

export async function updateNationalHoliday(nationalHoliday: NationalHoliday): Promise<NationalHoliday> {
    const {data} = await api<NationalHoliday>({
        method: 'put',
        url: 'Timesheets/holidays',
        data: nationalHoliday,
    });

    return data;
}
