






















import AppDialog from '@/shared/AppDialog.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        AppDialog,
    },
})
export default class ConfirmRecordDeletionModal extends Vue {

    @Prop(String)
    recordToDelete!: string;

    key = '';
    polish ='';
    english = '';

    get deleteRecordConfirmBody(): string {
        if (!this.recordToDelete) {
            return '';
        }
        return this.$t('stringDictionaryConfirmDeleteBody', {key: this.recordToDelete}) as string;
    }
}

