



















import {Component, Mixins} from 'vue-property-decorator';
import {synchronize} from '@/system/store/endpoints';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {SystemBtnMixin} from '@/system/shared/SystemBtnMixin';

@Component
export default class SyncBtn extends Mixins(ApiCall, SystemBtnMixin) {
    async onClick(): Promise<void> {
        await this.apiCall(synchronize);
    }
}
